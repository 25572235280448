import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import * as uikit from 'uikit';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState = null;
  user;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
    this.afAuth.authState.subscribe(auth => {
      this.authState = auth;
    });
  }

  get authenticated(): boolean {
    return this.authState !== null;
  }

  setUser(user) { this.user = user; }

  getAuthenticated(): Observable<any> {
    return this.afAuth.authState;
  }

  /* Sign in */
  signIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        uikit.notification('Successfully signed in!', {status: 'success'});
        this.router.navigate(['admin']);
      })
      .catch(err => {
        uikit.notification(err.message, {status: 'danger'});
      });
  }

  signOut() {
    return this.afAuth.signOut()
      .then(() => {
        uikit.notification('Successfully signed out!', {status: 'primary'});
        return this.router.navigate(['/']);
      })
      .catch(err => uikit.notification(err.message, {status: 'danger'}))
    ;
  }
}
