<nav class="uk-navbar-container desktop-navbar" uk-navbar>
  <div class="uk-navbar-left">
    <ul class="uk-navbar-nav">
      <li class="uk-active"><a href="/"><img src="/assets/images/logos/logo.svg" alt="" class="logo"></a></li>
    </ul>
  </div>

  <div class="uk-navbar-right">
    <ul class="uk-navbar-nav">
      <li class="">
        <a routerLink="/"
           routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <span>HOME</span>
        </a>
      </li>
      <li class="">
        <a routerLink="/aziende"
           routerLinkActive="active">
          <span>Aziende</span>
        </a>
      </li>
      <li class="">
        <a routerLink="/chi-siamo"
           routerLinkActive="active">
          <span>Chi siamo</span>
        </a>
      </li>
      <li class="">
        <a routerLink="/news-e-eventi"
           routerLinkActive="active">
          <span>News & Eventi</span>
        </a>
      </li>
      <li class="">
        <a routerLink="/contatti"
           routerLinkActive="active">
          <span>Contatti</span>
        </a>
      </li>
      <li class="" *ngIf="auth.authenticated">
        <a (click)="auth.signOut()">
          <span>Log out</span>
        </a>
      </li>
    </ul>
  </div>
</nav>

<nav class="uk-navbar-container uk-navbar-transparent mobile-navbar" uk-navbar>
  <div class="uk-navbar-left">
    <ul class="uk-navbar-nav">
      <li class="uk-active"><a href="/"><img src="/assets/images/logos/logo.svg" alt="" class="logo"></a></li>
    </ul>
  </div>
  <div class="uk-navbar-right">
    <ul class="uk-navbar-nav">
      <li>
        <a class="uk-navbar-toggle" (click)="toggleMobileNavigation()">
          <span class="uk-margin-small-right">Menu</span> <span uk-navbar-toggle-icon></span>
        </a>
      </li>
    </ul>
  </div>
  <ul class="uk-list uk-list-large uk-list-divider" [ngClass]="{'expanded': expandMobileMenu}">
    <li class="">
      <a routerLink="/"
         routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMobileNavigation()">
        <span>Home</span>
      </a>
    </li>
    <li class="">
      <a routerLink="/aziende"
         routerLinkActive="active" (click)="toggleMobileNavigation()">
        <span>Aziende</span>
      </a>
    </li>
    <li class="">
      <a routerLink="/chi-siamo"
         routerLinkActive="active" (click)="toggleMobileNavigation()">
        <span>Chi siamo</span>
      </a>
    </li>
    <li class="">
      <a routerLink="/news-e-eventi"
         routerLinkActive="active" (click)="toggleMobileNavigation()">
        <span>News & Eventi</span>
      </a>
    </li>
    <li class="">
      <a routerLink="/contatti"
         routerLinkActive="active" (click)="toggleMobileNavigation()">
        <span>Contatti</span>
      </a>
    </li>
    <li class="" *ngIf="auth.authenticated">
      <a (click)="auth.signOut()" (click)="toggleMobileNavigation()">
        <span>Log out</span>
      </a>
    </li>
  </ul>
</nav>
