import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {Observable, Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {filter, finalize, take, takeUntil, tap} from 'rxjs/operators';
import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements AfterViewInit, OnDestroy {

  @Input() file: File;
  @Output() imageData: EventEmitter<{path: string, url: string}> = new EventEmitter<{path: string; url: string}>();

  task: AngularFireUploadTask;
  downloadUrl: string;
  percentage: Observable<number>;
  snapshot: Observable<any>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private storage: AngularFireStorage) { }

  ngAfterViewInit() {
    this.startUpload();
  }

  startUpload() {
    console.log('Start upload: ', this.file);
    const path = `${Date.now()}_${this.file.name}`;

    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, this.file);

    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges()
      .pipe(
        tap(console.log),
        finalize(async () => {
          this.downloadUrl = await ref.getDownloadURL().toPromise();
          this.imageData.emit({url: this.downloadUrl, path});
        })
      );
  }

  isActive(snapshot) {
    return snapshot.state === 'running'
    && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  ngOnDestroy() {
    console.log('DESTROYYYY');
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
