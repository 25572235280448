<div class="uk-padding-large uk-background-muted">
  <h1 class="uk-text-center">News & Eventi</h1>
  <div class="articles-wrapper uk-margin-large-top uk-margin-large-bottom uk-container">
    <ng-container *ngFor="let articolo of articles$ | async" >

      <article class="uk-article uk-margin-medium uk-background-default uk-padding">
        <header class="uk-comment-header">
          <div class="uk-grid-medium uk-flex-middle header-grid" uk-grid>
            <div class="uk-width-auto image-container uk-inline">
              <img src="{{articolo.immagini[0]?.url || ''}}" alt="" class="uk-comment-avatar immagine-articolo">
              <div uk-lightbox *ngIf="articolo.immagini.length > 1">
                <a href="{{articolo?.immagini[0]?.url || ''}}">
                  <span uk-icon="album" uk-tooltip="title:  Apri galleria; pos: right"></span>
                </a>
                <ng-container *ngIf="articolo.immagini.length > 1">
                  <a href="{{image.url}}" *ngFor="let image of articolo.immagini.slice(1)"></a>
                </ng-container>
              </div>
            </div>
            <div class="uk-width-expand">
              <h4 class="uk-comment-title uk-margin-small-bottom">{{articolo.titolo}}</h4>
              <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                <li>{{articolo.data | date}}</li>
                <li *ngIf="articolo.location"><span uk-icon="location" class="uk-margin-small-right"></span>{{articolo.location}}</li>
              </ul>
              <p class="uk-text-italic">{{articolo.sottotitolo}}</p>
              <div class="uk-button uk-button-text uk-text-left" (click)="goTo(articolo.link)" *ngIf="articolo.link">SCOPRI DI PIÙ</div>
            </div>
          </div>
        </header>
        <div class="uk-comment-body">
          <p>{{articolo.testo}}</p>
        </div>
      </article>
      <hr class="uk-divider-icon">
    </ng-container>
  </div>
</div>
