import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaAziendeComponent } from './lista-aziende/lista-aziende.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { NewsComponent } from './news/news.component';
import { ContattiComponent } from './contatti/contatti.component';
import { HomeComponent } from './home/home.component';
import {RouterModule} from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {SharedModule} from '../shared/shared.module';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AdminModule} from './admin/admin.module';



@NgModule({
  declarations: [ListaAziendeComponent, ChiSiamoComponent, NewsComponent, ContattiComponent, HomeComponent, DashboardComponent, NotFoundComponent, LoginComponent],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule
  ],
  exports: []
})
export class CoreModule { }
