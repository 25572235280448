<div class="slideshow">
  <div class="uk-overflow-hidden uk-flex uk-flex-top">
    <div class="uk-width-3-3@m uk-text-center uk-margin-auto uk-margin-auto-vertical">


      <div uk-slideshow="animation: push; max-height: 640; autoplay: true">

        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

          <ul class="uk-slideshow-items">
            <li>
              <img src="/assets/images/slideshow/grapes_2.jpg" alt="" uk-cover>
              <div class="uk-overlay uk-overlay-primary uk-position-right uk-text-left uk-transition-slide-right uk-width-large">
                <h3 class="uk-margin-remove uk-heading-bullet uk-heading-small">Esperienza e tradizione</h3>
                <h5 class="uk-margin-small-top uk-text-lead">L’agenzia nasce nel 1970 ad opera di Armando Marra, pioniere nella vendita di vino nel settore Ho.Re.Ca a Napoli.</h5>
              </div>
            </li>
            <li>
              <img src="/assets/images/slideshow/wine.jpg" alt="" uk-cover>
              <div class="uk-overlay uk-overlay-primary uk-position-left uk-text-right uk-transition-slide-left uk-width-large">
                <h3 class="uk-margin-remove uk-heading-bullet uk-heading-small">Continuità e impegno</h3>
                <h5 class="uk-margin-small-top uk-text-lead">Prosegue poi con i suoi figli Maurizio e Massimo negli anni '80.</h5>
              </div>
            </li>
            <li>
              <img src="/assets/images/slideshow/wine-cellar.jpg" alt="" uk-cover>
              <div class="uk-overlay uk-overlay-primary uk-position-right uk-text-left uk-transition-slide-right uk-width-large">
                <h3 class="uk-margin-remove uk-heading-bullet uk-heading-small">Competenza e partecipazione</h3>
                <h5 class="uk-margin-small-top uk-text-lead">L’attività è condotta attualmente da Massimo Marra e dal suo staff con passione e amore per il vino.</h5>
              </div>
            </li>
          </ul>

          <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
          <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

          <div class="uk-position-bottom-center">
            <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="partners">
  <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider="autoplay: true">
    <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-6@m uk-grid">
      <li *ngFor="let azienda of (data.aziende$ | async)" routerLink="/aziende" [queryParams]="{azienda: azienda.nome}">
        <div class="uk-panel uk-padding-small">
          <img src="{{azienda.logo.url}}" alt="{{azienda.nome}}">
        </div>
      </li>
    </ul>
    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
  </div>
</div>
<div class="azienda uk-padding-large uk-dark uk-background-primary">
  <div class="uk-container">
    <div class="uk-child-width-1-2@s" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-large uk-card-body">
          <h1 class="">vini</h1>
          <p class="testo">Una panoramica di etichette ampia e variegata, che spazia dalle realtà enologiche campane e
            italiane fino alle proposte di eccellenze internazionali, per fornire la soluzione più adatta ad ogni cliente.</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-large uk-card-body">
          <h1 class="">bolle</h1>
          <p class="testo">Nell'affascinante mondo delle bollicine, la nostra selezione è arricchita da produzioni
            nazionali ed estere con un'offerta attenta sia ai vigneron nostrani sia alle piccole o grandi maison d'oltralpe.</p>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-2@s uk-margin" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-large uk-card-body">
          <h1 class="">target</h1>
          <p class="testo">Ci rivolgiamo al canale Ho.Re.Ca. Ristoranti, Enoteche, Winebar, Hotel come ambasciatori
            delle nostre aziende per soddisfare curiosità e richieste sui prodotti, garantendone una scelta mirata.</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-large uk-card-body">
          <h1 class="">consulenza</h1>
          <p class="testo">Con competenza tecnica e commerciale possiamo indicare i vini più appropriati nella
            creazione o nell'ampliamento di carte dei vini e dell'offerta di beverage più efficace per ogni diversa
            tipologia di attività di ristorazione.</p>
        </div>
      </div>
    </div>
  </div>
</div>






