import { Component, OnInit } from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.scss']
})
export class ChiSiamoComponent implements OnInit {

  teamMembers$: Observable<any>;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.teamMembers$ = this.data.teamMembers$;
  }

}
