<div class="uk-padding-large uk-background-muted">
  <div class="uk-container">
    <h1 class="uk-text-center">Le Nostre Aziende</h1>
    <ul class="uk-padding-large uk-padding-remove-top" uk-accordion="active: 0">
      <li *ngFor="let zona of zone; let i = index" class="uk-open">
        <a class="uk-accordion-title"><h4 class="uk-heading-bullet uk-margin-small-bottom">{{zona.nome | uppercase}}</h4></a>
        <div class="uk-accordion-content">
          <div class="card uk-margin uk-flex uk-background-default" *ngFor="let azienda of zona.aziende">
            <div class="card-logo uk-padding-small uk-width-1-2">
              <img src="{{azienda.logo.url}}" alt="">
            </div>
            <div class="card-body uk-margin-small-left uk-flex uk-flex-column uk-padding-small uk-width-1-2">
              <h3 class="uk-card-title" #aziendeNameText>{{azienda.nome}}</h3>
              <p class="uk-text-italic">{{azienda.descrizione}}.</p>
              <button class="uk-button uk-button-text uk-text-left" (click)="goTo(azienda.website_url)">Vai al sito</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>


</div>

