<div class="uk-background-muted uk-container">
  <div class="uk-text-center uk-padding-large">
    <h1>Contatti</h1>
    <ul class="uk-list uk-margin-large-top uk-margin-large-bottom">
      <li class="uk-text-lead">MASSIMO MARRA RAPPRESENTANZE</li>
      <li class="uk-margin-medium-top uk-text-meta"><a href="{{addressRedirectUrl}}" target="_blank"><span uk-icon="location"></span>&nbsp;Via Domenico di Gravina n. 9/A - 80136 Napoli</a></li>
      <li class="uk-text-muted uk-margin-medium-top"><a href="mailto:info@massimomarra.com"><span uk-icon="mail" class="uk-margin-small-right"></span>info@massimomarra.com</a></li>
    </ul>
    <ul class="uk-list uk-margin-medium-top">
      <ng-container *ngFor="let member of teamMembers$ | async">
        <li class="uk-text-bold uk-margin-medium-top">{{member.nome}}</li>
        <li class="uk-text-muted"><a href="tel:+39{{member.telefono}}"><span uk-icon="receiver" class="uk-margin-small-right"></span>{{member.telefono}}</a></li>
        <li class="uk-text-muted"><a href="mailto:{{member.email}}"><span uk-icon="mail" class="uk-margin-small-right"></span>{{member.email}}</a></li>
      </ng-container>
    </ul>
  </div>
</div>
