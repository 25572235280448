import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../shared/services/data.service';
import {Observable} from 'rxjs';
import {Articolo} from '../../../models/articolo';
import {AngularFireStorage} from '@angular/fire/storage';
import * as uikit from 'uikit';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {switchMap, take} from 'rxjs/operators';
import firebase from 'firebase';
import FieldValue = firebase.firestore.FieldValue;

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  articoli$: Observable<Articolo[]>;
  articoloForm: FormGroup;
  destroyUploader = false;
  newDocumentId: string;
  images = [];


  constructor(private data: DataService, private storage: AngularFireStorage) {
    this.articoloForm = new FormGroup({
      id: new FormControl('', [Validators.required]),
      titolo: new FormControl('', [Validators.required]),
      testo: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      sottotitolo: new FormControl(null, [Validators.required]),
      data: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required]),
      immagini: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.articoli$ = this.data.articoli$;

    // Add event listeners to forms
    uikit.util.on('#crea-nuovo', 'show', () => {
      this.articoloForm.reset();
      this.newDocumentId = Date.now().toString();
    });

    uikit.util.on('#crea-nuovo', 'hide', () => {
      this.articoloForm.reset();
      this.destroyUploader = true;
    });

    uikit.util.on('#aggiorna', 'hide', () => {
      this.destroyUploader = true;
    });
  }

  createNewArticolo(payload: Articolo) {
    const modalEl = document.getElementById('crea-nuovo');

    return this.data.create(`articoli/${this.newDocumentId}`, {
      ...payload,
      immagini: this.images
    }).subscribe(() => {
      alert('Nuovo documento creato con successo!');
      this.articoloForm.reset();
      uikit.modal(modalEl).hide();
      this.images = [];
      this.destroyUploader = true;
      // this.uploadCanStart.unsubscribe();
    });
  }

  updateArticolo(payload: Articolo) {
    const modalEl = document.getElementById('aggiorna');
    return this.data.update(`articoli/${payload.id}`, {
      ...payload,
      immagini: FieldValue.arrayUnion(...this.images)
    }).subscribe(() => {
      uikit.modal(modalEl).hide();
      alert('Documento aggiornato con successo!');
      this.articoloForm.reset();
      this.images = [];
      this.destroyUploader = true;
    });
  }


  openUpdateModal(documentData) {
    const modalEl = document.getElementById('aggiorna');
    this.articoloForm.patchValue({...documentData});
    uikit.modal(modalEl).show();
  }

  deleteArticolo(articolo: Articolo) {
    if (confirm('Sicuro di voler procedere?')) {
      for (const img of articolo.immagini) {
        this.storage.ref(img.path).delete().pipe(take(1)).subscribe();
      }
      return this.data.delete('articoli/' + articolo.id);
    }
  }

  deleteArticoloImg(img: {path: string, url: string}, docId: string) {
    this.data.update(`articoli/${docId}`, {
      immagini: FieldValue.arrayRemove(img)
    }).pipe(
      switchMap(() => this.storage.ref(img.path).delete()),
      take(1)
    ).subscribe(() => {
      const images = this.articoloForm.controls['immagini'].value;
      console.log('Images: ', images);
      images.splice(images.indexOf(img), 1);
      console.log('Images after splice: ', images);
      this.articoloForm.controls['immagini'].setValue(images);
    });
  }

  addImage(event) {
    this.images.push(event);
  }

}
