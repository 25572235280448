<button class="uk-button uk-button-default uk-margin-small-bottom uk-float-right" type="button"
        uk-toggle="target: #crea-nuovo" (click)="destroyUploader = false">Nuovo</button>
<div class="table-wrapper">
  <table class="uk-table uk-table-hover uk-table-divider">
    <thead>
    <tr>
      <th>#</th>
      <th>Logo</th>
      <th>Nome</th>
      <th>Descrizione</th>
      <th>Sito web</th>
      <th>Zona</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let azienda of (aziende$ | async)">
      <td>{{azienda.ordine}}</td>
      <td><img src="{{azienda?.logo?.url}}" alt="" class="uk-border-circle" width="40" height="40"></td>
      <td>{{azienda.nome}}</td>
      <td>{{azienda.descrizione.substr(0, 50)}}<span *ngIf="azienda.descrizione.length > 50">...</span></td>
      <td>{{azienda.website_url.substr(0, 50)}}<span *ngIf="azienda.website_url.length > 50">...</span></td>
      <td>{{azienda.zona}}</td>
      <td>
        <a class="uk-icon-link uk-margin-small-right" uk-icon="pencil" (click)="openUpdateModal(azienda)"></a>
        <a class="uk-icon-link uk-margin-small-left" uk-icon="trash" (click)="deleteAzienda(azienda)"></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>


<!-- Form per creazione nuovo documento -->
<div id="crea-nuovo" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="aziendeForm" (ngSubmit)="createNewAzienda(aziendeForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Nuova azienda</legend>
        <div class="uk-margin">
          <label>Nome</label>
          <input type="text" class="uk-input" formControlName="nome" placeholder="Cantine Italiane Srl">
        </div>
        <div class="uk-margin">
          <label>
            Descrizione
            <textarea class="uk-textarea" rows="10" formControlName="descrizione" name="descrizione"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Website
            <input type="text" placeholder="mario@rossi.com" formControlName="website_url" class="uk-input">
          </label>
          <label>
            Zona
            <select class="uk-select" formControlName="zona">
              <option value="{{zona.id}}" *ngFor="let zona of zone$ | async">{{zona.copy}}</option>
            </select>
          </label>
          <label>
            Ordine
            <input type="number" formControlName="ordine" class="uk-input">
          </label>
        </div>
      </fieldset>
      <label>
        Logo
        <app-uploader [showDropBox]="false"
                      (imageData)="aziendeForm.controls['logo'].setValue($event)" *ngIf="!destroyUploader"></app-uploader>
      </label>
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>

<!--Form per aggiornamento documento esistente-->
<div id="aggiorna" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="aziendeForm" (ngSubmit)="updateAzienda(aziendeForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Aggiorna informazioni azienda</legend>
        <div class="uk-margin">
          <label>Nome</label>
          <input type="text" class="uk-input" formControlName="nome" placeholder="Mario Rossi">
        </div>
        <div class="uk-margin">
          <label>
            Descrizione
            <textarea class="uk-textarea" rows="10" formControlName="descrizione" name="descrizione"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Website
            <input type="text" placeholder="www.cantineitaliane.it" formControlName="website_url" class="uk-input">
          </label>
          <label>
            Zona
            <select class="uk-select">
              <option value="{{zona.id}}" *ngFor="let zona of zone$ | async">{{zona.copy}}</option>
            </select>
          </label>
          <label>
            Ordine
            <input type="number" formControlName="ordine" class="uk-input">
          </label>
        </div>
      </fieldset>

      <ng-container *ngIf="aziendeForm.getRawValue() as formData">
        <div class="profile-picture-container" *ngIf="formData.logo; else showUploader" (click)="deleteLogoImg(formData?.logo.path, formData.id)">
          <img [src]="aziendeForm.getRawValue()?.logo?.url" width="100" height="100" alt="">
          <span uk-icon="icon: trash; ratio: 3" class="overlay"></span>
        </div>

        <ng-template #showUploader>
          <label>
            Foto profilo
            <app-uploader [showDropBox]="false"
                          (imageData)="aziendeForm.controls['logo'].setValue($event)"></app-uploader>
          </label>
        </ng-template>
      </ng-container>


      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>
