<header class="uk-background-muted">
  <app-navbar uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky, uk-navbar-transparent; top: 200"></app-navbar>
</header>
<router-outlet></router-outlet>
<footer class="uk-background-secondary uk-padding-large uk-flex uk-flex-column">
  <div class="top-section uk-flex uk-flex-between">
    <div class="first uk-width-2-5 uk-text-center">
      <ul class="info uk-list">
        <li class="uk-text-uppercase">Marra Massimo Rappresentanze</li>
        <li><span uk-icon="location"></span>&nbsp;Via Domenico di Gravina n. 9/A - 80136 Napoli</li>
        <li><span uk-icon="receiver"></span>&nbsp;338 9670643</li>
        <li><a href="mailto:info@massimomarra.com"><span uk-icon="mail" class="uk-margin-small-right"></span>info@massimomarra.com</a></li>
      </ul>
    </div>
    <hr class="uk-divider-vertical">
    <div class="uk-width-1-5 uk-flex uk-flex-center">
      <div class="logo-container">
        <a href="/"><img src="/assets/images/logos/logo-white.svg" alt="" class="logo" width="200" uk-img></a>
      </div>
    </div>
    <hr class="uk-divider-vertical">
    <div class="third uk-width-2-5">
      <ul class="uk-list uk-text-center">
        <li class="uk-text-uppercase"><a routerLink="/">Home</a></li>
        <li class="uk-text-uppercase"><a routerLink="/aziende">Aziende</a></li>
        <li class="uk-text-uppercase"><a routerLink="/chi-siamo">Chi siamo</a></li>
        <li class="uk-text-uppercase"><a routerLink="/news-e-eventi">News & Eventi</a></li>
      </ul>
    </div>
  </div>
  <hr>
  <div class="bottom-section uk-light">
    <p class="uk-text-meta">Copyright &copy; {{currentYear}} Marra Massimo Rappresentanze. Tutti i diritti riservati.</p>
  </div>
</footer>
