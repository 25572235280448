<div class="uk-padding-large uk-background-muted">
  <div class="uk-container">
    <h1 class="uk-text-center">Chi siamo</h1>
    <div class="section uk-margin-medium-bottom uk-panel uk-flex" *ngFor="let member of (teamMembers$ | async); let i = index;" [ngClass]="{'invert-order': i % 2 === 0}">
      <div class="uk-width-2-5 uk-background-muted uk-flex uk-flex-right profile-image-container">
        <img src="{{member.foto_profilo.url}}" alt="" class="profile-image">
      </div>
      <ul class="uk-width-3-5 uk-background-default uk-text-left uk-list uk-padding uk-margin-remove info">
        <li><h3 class="uk-card-title uk-margin-large-bottom">{{member.nome}}</h3></li>
        <li class="uk-text" *ngFor="let el of member.bio.split('\n')">{{el}}</li>
        <li class="uk-text-muted uk-margin-large-top"><a href="tel:+39{{member.telefono}}"><span uk-icon="receiver" class="uk-margin-small-right"></span>{{member.telefono}}</a></li>
        <li class="uk-text-muted"><a href="mailto:{{member.email}}"><span uk-icon="mail" class="uk-margin-small-right"></span>{{member.email}}</a></li>
      </ul>
    </div>
<!--    <div class="section uk-padding uk-panel uk-flex invert-order uk-margin-large-bottom">-->
<!--      <ul class="uk-width-3-5 uk-background-default uk-list uk-padding uk-text-right info uk-margin-remove ai-fe">-->
<!--        <li><h3 class="uk-card-title">Pietro Fiorini</h3></li>-->
<!--        <li class="uk-text uk-margin-large-top">Iscritto alla S.E.S., Scuola Europea Sommelier, dal 2018 e Sommelier dal 2020</li>-->
<!--        <li class="uk-text-muted uk-margin-large-top"><a href="tel:+393756503727"><span uk-icon="receiver" class="uk-margin-small-right"></span>3756503727</a></li>-->
<!--        <li class="uk-text-muted"><a href="mailto:pfiorini.marraufficio@gmail.com"><span uk-icon="mail" class="uk-margin-small-right"></span>pfiorini.marraufficio@gmail.com</a></li>-->
<!--      </ul>-->
<!--      <div class="uk-width-2-5 uk-background-muted profile-image-container">-->
<!--        <img src="assets/images/staff/profi_pf.jpeg" alt="" class="profile-image">-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
