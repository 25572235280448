import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/auth.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  email = new FormControl('', [Validators.email]);
  password = new FormControl(null);
  constructor(private auth: AuthService) {

  }

  ngOnInit(): void {
  }

  login() {
    console.log({email: this.email.value, password: this.password.value});
    return this.auth.signIn(this.email.value, this.password.value);
  }

}
