import { Component, OnInit } from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {

  mapUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=40.85798678455794,14.243675255568625&zoom=15&size=600x300&maptype=roadmap\n' +
    '&markers=color:red%7Clabel:M%7C40.85798678455794,14.243675255568625\n' +
    '&key=AIzaSyCCAlU0yd562CxtFtvbHu51PnFyJ5RsI0Q';
  addressRedirectUrl = 'https://www.google.com/maps/place/Via+Domenico+di+Gravina,+9,+80136+Napoli+NA/@40.8578448,14.2414651,17z/data=!3m1!4b1!4m5!3m4!1s0x133b08616e2ff205:0x8097b2f77b62a76!8m2!3d40.8578448!4d14.2436538';
  teamMembers$: Observable<any>;

  constructor(private data: DataService) {
    this.teamMembers$ = this.data.teamMembers$;
  }

  ngOnInit(): void {
  }

}
