<div class="uk-container uk-container-xsmall uk-margin-large-top uk-margin-large-bottom">
  <div class="uk-padding-large">
    <form>
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Log in</legend>
        <div class="uk-margin">
          <label>Email
            <input type="email" class="uk-input" placeholder="andrea@rossi.com" [formControl]="email">
          </label>
        </div>
        <div class="uk-margin">
          <label>
            <input type="password" class="uk-input" [formControl]="password">
          </label>
        </div>
      </fieldset>
    </form>
    <button class="uk-button uk-button-default" (click)="login()">Log in!</button>
  </div>
</div>
