import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../shared/services/data.service';
import {Observable} from 'rxjs';
import {TeamMember} from '../../../models/team-member';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as uikit from 'uikit';
import {AngularFireStorage} from '@angular/fire/storage';
import {switchMap, take} from 'rxjs/operators';


@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  staff$: Observable<TeamMember[]>;
  staffForm: FormGroup;
  destroyUploader = false;

  newDocumentId: string;


  constructor(private data: DataService, private storage: AngularFireStorage) {
    this.staffForm = new FormGroup({
      id: new FormControl('', [Validators.required]),
      nome: new FormControl('', [Validators.required]),
      bio: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      foto_profilo: new FormControl(null, [Validators.required]),
      telefono: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      ordine: new FormControl('', [Validators.required])
    });

  }

  ngOnInit(): void {
    this.staff$ = this.data.teamMembers$;

    uikit.util.on('#crea-nuovo', 'hide', () => {
      this.staffForm.reset();
    });

    uikit.util.on('#crea-nuovo', 'show', () => {
      this.newDocumentId = Date.now().toString();
    });
  }

  createNewStaff(payload: TeamMember) {
    const modalEl = document.getElementById('crea-nuovo');

    return this.data.create(`staff/${this.newDocumentId}`, {
      ...payload
    }).subscribe(() => {
      alert('Nuovo documento creato con successo!');
      this.staffForm.reset();
      uikit.modal(modalEl).hide();
      this.destroyUploader = true;
      // this.uploadCanStart.unsubscribe();
    });
  }

  updateStaff(payload: TeamMember) {
    const modalEl = document.getElementById('aggiorna');
    return this.data.update(`staff/${payload.id}`, {
      ...payload
    }).subscribe(() => {
      uikit.modal(modalEl).hide();
      alert('Documento aggiornato con successo!');
      this.staffForm.reset();
    });
  }

  deleteStaff(teamMember: TeamMember) {
    if (confirm('Sicuro di voler procedere?')) {
      const profileImgPath = teamMember.foto_profilo?.path;
      if (profileImgPath) {
        this.storage.ref(profileImgPath).delete().pipe(take(1)).subscribe();
      }
      return this.data.delete('staff/' + teamMember.id);
    }
  }

  deleteProfileImg(path: string, docId: string) {
    this.data.update(`staff/${docId}`, {
      foto_profilo: null
    }).pipe(
      switchMap(() => this.storage.ref(path).delete()),
      take(1)
    ).subscribe(() => {
      this.staffForm.controls['foto_profilo'].setValue(null);
    });
  }

  openUpdateModal(documentData) {
    const modalEl = document.getElementById('aggiorna');
    this.staffForm.patchValue({...documentData});
    uikit.modal(modalEl).show();
  }
}
