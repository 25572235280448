import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @Input() showDropBox: boolean;
  @Output() imageData: EventEmitter<{ path: string, url: string }> = new EventEmitter<{path: string; url: string}>();
  @Input() uploadCanStart: Subject<boolean>;
  @Input() downloadUrl: any;



  isHovering: boolean;

  files: File[] = [];

  constructor() { }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  ngOnInit(): void {
  }

}
