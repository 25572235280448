import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {take, takeUntil} from 'rxjs/operators';
import {Observable, Subject, timer} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-lista-aziende',
  templateUrl: './lista-aziende.component.html',
  styleUrls: ['./lista-aziende.component.scss']
})
export class ListaAziendeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('aziendeNameText') cardHeaders: QueryList<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  aziende$: Observable<any>;
  zone = [];


  constructor(
    public data: DataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.aziende$ = this.data.aziende$
      .pipe(
        takeUntil(this.destroy$)
      );

    this.aziende$.subscribe(aziende$ => {
      for (const azienda of aziende$) {
        const found = this.zone.find(item => item.id === azienda.zona);
        if (found) {
          found.aziende.push(azienda);
        } else {
          this.zone.push({id: azienda.zona, nome: azienda.zona.replace(/_/g, ' '), aziende: [azienda]});
        }
      }
    });

  }

  ngAfterViewInit() {
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.azienda) {
      timer(1000, 500)
        .pipe(take(1))
        .subscribe(() => {
          this.scrollToView(queryParams.azienda);
        });
    }
  }

  goTo(url) {
    window.open('http://' + url, '_blank');
  }

  scrollToView(aziendaName: string) {
    const names = this.cardHeaders.toArray();
    const found = names.find(el => (el.nativeElement.innerText === aziendaName || el.nativeElement.textContent === aziendaName));
    if (found) {
      const foundNative = found.nativeElement;
      window.scroll({
        top: foundNative.getBoundingClientRect().top + window.scrollY - 350,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
