<div *ngIf="percentage | async as pct">
  <progress class="uk-progress" value="{{pct}}">{{pct | number}}%</progress>
</div>


<div *ngIf="snapshot | async as snap">
  <div *ngIf="downloadUrl as url">
    <img [src]="url" alt="media"><br>
  </div>
  <hr>
  <button (click)="task.pause()" [disabled]="!isActive(snap)">Pausa</button>
  <button (click)="task.cancel()" [disabled]="!isActive(snap)">Annulla</button>
  <button (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">Riprendi</button>
</div>
