import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  expandMobileMenu = false;

  constructor(public auth: AuthService) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
  }

  toggleMobileNavigation() {
    this.expandMobileMenu = !this.expandMobileMenu;
  }

}
