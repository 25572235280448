<div class="dropzone"
     appDropzone
     (hovered)="toggleHover($event)"
     (dropped)="onDrop($event)"
     [class.hovering]="isHovering"
     *ngIf="showDropBox; else singleInput"
>
  <p>Trascina qui i file da caricare</p>
  <div class="file">
    <label class="file-label">
      <input type="file" class="file-input" (change)="onDrop($event.target.files)">
    </label>
  </div>
</div>

<ng-template #singleInput>
  <div class="file">
    <label class="file-label">
      <input type="file" class="file-input" (change)="onDrop($event.target.files)" #fileInput>
    </label>
  </div>
</ng-template>

<h5>Uploads</h5>
<div *ngFor="let file of files" class="tasks-container">
  <app-upload-task [file]="file" (imageData)="imageData.emit($event)"></app-upload-task>
</div>
