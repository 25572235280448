import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {shareReplay} from 'rxjs/operators';
import {Azienda} from '../../models/azienda';
import {Articolo} from '../../models/articolo';
import {TeamMember} from '../../models/team-member';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Zona} from '../../models/zona';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  aziende$: Observable<Azienda[]>;
  articoli$: Observable<Articolo[]>;
  teamMembers$: Observable<TeamMember[]>;
  zone$: Observable<Zona[]>;

  constructor(private afs: AngularFirestore) {
    this.aziende$ = this.afs.collection('aziende', ref => ref.orderBy('ordine', 'asc')).valueChanges().pipe(shareReplay()) as Observable<Azienda[]>;

    this.articoli$ = this.afs.collection('articoli', ref => ref.orderBy('data', 'desc')).valueChanges().pipe(shareReplay()) as Observable<Articolo[]>;

    this.teamMembers$ = this.afs.collection('staff', ref => ref.orderBy('ordine', 'asc')).valueChanges().pipe(shareReplay()) as Observable<TeamMember[]>;

    this.zone$ = this.afs.collection('zone').valueChanges().pipe(shareReplay()) as Observable<Zona[]>;
  }

  create(documentPath: string, payload: any): Observable<any> {
    const docRef = this.afs.doc(documentPath);
    return fromPromise(docRef.set({
      ...payload,
      id: docRef.ref.id,
      created_at: new Date(),
      last_updated: new Date(),
    }, {merge: true}));
  }

  update(documentPath: string, payload: any) {
    return fromPromise(this.afs.doc(documentPath).set({
      ...payload,
      last_updated: new Date()
    }, {merge: true}));
  }

  delete(documentPath: string) {
    return this.afs.doc(documentPath).delete();
  }
}
