<button class="uk-button uk-button-default uk-margin-small-bottom uk-float-right" type="button"
        uk-toggle="target: #crea-nuovo" (click)="destroyUploader = false">Nuovo
</button>
<div class="table-wrapper">
  <table class="uk-table uk-table-hover uk-table-divider">
    <thead>
    <tr>
      <th>Titolo</th>
      <th>Data</th>
      <th>Testo</th>
      <th>Location</th>
      <th>Link</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let articolo of articoli$ | async">
      <td>{{articolo.titolo}}</td>
      <td>{{articolo.data | date}}</td>
      <td>{{articolo.testo?.substr(0, 50)}}<span *ngIf="articolo.testo?.length > 50">...</span></td>
      <td>{{articolo.location?.substr(0, 50)}}<span *ngIf="articolo.location?.length > 50">...</span></td>
      <td>{{articolo.link?.substr(0, 50)}}<span *ngIf="articolo.link?.length > 50">...</span></td>
      <td>
        <a class="uk-icon-link uk-margin-small-right" uk-icon="pencil" (click)="openUpdateModal(articolo); destroyUploader = false"></a>
        <a class="uk-icon-link uk-margin-small-left" uk-icon="trash" (click)="deleteArticolo(articolo)"></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>



<!-- Form per creazione nuovo documento -->
<div id="crea-nuovo" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="articoloForm" (ngSubmit)="createNewArticolo(articoloForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Nuovo articolo</legend>
        <div class="uk-margin">
          <label>Titolo
            <input type="text" class="uk-input" formControlName="titolo">
          </label>
        </div>
        <div class="uk-margin">
          <label>Sottotitolo
            <input type="text" class="uk-input" formControlName="sottotitolo">
          </label>
        </div>
        <div class="uk-margin">
          <label>Data
            <input type="datetime-local" class="uk-input" formControlName="data">
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Testo
            <textarea class="uk-textarea" rows="10" formControlName="testo" name="testo"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Link
            <input type="text" formControlName="link" class="uk-input">
          </label>
          <label>
            Location
            <input type="text" formControlName="location" class="uk-input">
          </label>
        </div>
      </fieldset>
      <label>
        Logo
        <app-uploader [showDropBox]="true"
                      (imageData)="addImage($event)" *ngIf="!destroyUploader"></app-uploader>
      </label>
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>


<!--Form per aggiornamento documento esistente-->
<div id="aggiorna" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="articoloForm" (ngSubmit)="updateArticolo(articoloForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Aggiorna articolo</legend>
        <div class="uk-margin">
          <label>Titolo
            <input type="text" class="uk-input" formControlName="titolo">
          </label>
        </div>
        <div class="uk-margin">
          <label>Sottotitolo
            <input type="text" class="uk-input" formControlName="sottotitolo">
          </label>
        </div>
        <div class="uk-margin">
          <label>Data
            <input type="datetime-local" class="uk-input" formControlName="data">
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Testo
            <textarea class="uk-textarea" rows="10" formControlName="testo" name="testo"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Link
            <input type="text" formControlName="link" class="uk-input">
          </label>
          <label>
            Location
            <input type="text" formControlName="location" class="uk-input">
          </label>
        </div>
      </fieldset>

      <ng-container *ngIf="articoloForm.getRawValue() as formData">
        <div class="pictures-wrap">
          <div class="articolo-picture-container uk-margin-small" *ngFor="let img of formData.immagini"
               (click)="deleteArticoloImg(img, formData.id)">
            <img [src]="img.url" width="100" height="100" alt="">
            <span uk-icon="icon: trash; ratio: 3" class="overlay"></span>
          </div>
        </div>


        <label>
          Aggiungi immagini
          <app-uploader [showDropBox]="false"
                        (imageData)="addImage($event)" *ngIf="!destroyUploader"></app-uploader>
        </label>
      </ng-container>


      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>

