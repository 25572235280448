import { Component, OnInit } from '@angular/core';
import {DataService} from '../../shared/services/data.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  articles$: Observable<any>;
  constructor(private data: DataService) {

  }

  ngOnInit(): void {
    this.articles$ = this.data.articoli$;
  }

  goTo(url: string) {
    url.startsWith('http') ? window.open(url, '_blank') : window.open('http://' + url, '_blank');
  }

}
