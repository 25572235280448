<button class="uk-button uk-button-default uk-margin-small-bottom uk-float-right" type="button"
        uk-toggle="target: #crea-nuovo">Nuovo
</button>
<div class="table-wrapper">
  <table class="uk-table uk-table-hover uk-table-divider">
    <thead>
    <tr>
      <th>Ordine</th>
      <th>Foto Profilo</th>
      <th>Nome</th>
      <th>Bio</th>
      <th>Email</th>
      <th>Tel</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let staff of staff$ | async">
      <td>{{staff.ordine}}</td>
      <td><img src="{{staff.foto_profilo?.url}}" alt="" class="uk-border-circle" width="40" height="40"></td>
      <td>{{staff.nome}}</td>
      <td>{{staff.bio.substr(0, 50)}}<span *ngIf="staff.bio.length > 50">...</span></td>
      <td>{{staff.email}}</td>
      <td>{{staff.telefono}}</td>
      <td>
        <a class="uk-icon-link uk-margin-small-right" uk-icon="pencil" (click)="openUpdateModal(staff)"></a>
        <a class="uk-icon-link uk-margin-small-left" uk-icon="trash" (click)="deleteStaff(staff)"></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>


<!-- Form per creazione nuovo documento -->
<div id="crea-nuovo" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="staffForm" (ngSubmit)="createNewStaff(staffForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Nuovo membro dello staff</legend>
        <div class="uk-margin">
          <label>Nome e Cognome</label>
          <input type="text" class="uk-input" formControlName="nome" placeholder="Mario Rossi">
        </div>
        <div class="uk-margin">
          <label>
            Bio
            <textarea class="uk-textarea" rows="10" formControlName="bio" name="bio"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Email
            <input type="email" placeholder="mario@rossi.com" formControlName="email" class="uk-input">
          </label>
          <label>
            Telefono
            <input type="tel" placeholder="+39333333333" formControlName="telefono" class="uk-input">
          </label>
          <label>
            Ordine
            <input type="number" formControlName="ordine" class="uk-input">
          </label>
        </div>
      </fieldset>
      <label>
        Foto profilo
        <app-uploader [showDropBox]="false"
                      (imageData)="staffForm.controls['foto_profilo'].setValue($event)" *ngIf="!destroyUploader"></app-uploader>
      </label>
      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>

<!--Form per aggiornamento documento esistente-->
<div id="aggiorna" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Nuovo membr/o dello staff</h2>-->
    <form [formGroup]="staffForm" (ngSubmit)="updateStaff(staffForm.getRawValue())">
      <fieldset class="uk-fieldset">
        <legend class="uk-legend">Nuovo membro dello staff</legend>
        <div class="uk-margin">
          <label>Nome e Cognome</label>
          <input type="text" class="uk-input" formControlName="nome" placeholder="Mario Rossi">
        </div>
        <div class="uk-margin">
          <label>
            Bio
            <textarea class="uk-textarea" rows="10" formControlName="bio" name="bio"
                      placeholder="Lorem ipsum, dolor sit amet..."></textarea>
          </label>
        </div>
        <div class="uk-margin">
          <label>
            Email
            <input type="email" placeholder="mario@rossi.com" formControlName="email" class="uk-input">
          </label>
          <label>
            Telefono
            <input type="tel" placeholder="+39333333333" formControlName="telefono" class="uk-input">
          </label>
          <label>
            Ordine
            <input type="number" formControlName="ordine" class="uk-input">
          </label>
        </div>
      </fieldset>

      <ng-container *ngIf="staffForm.getRawValue() as formData">
        <div class="profile-picture-container" *ngIf="formData.foto_profilo; else showUploader" (click)="deleteProfileImg(formData?.foto_profilo.path, formData.id)">
          <img [src]="staffForm.getRawValue()?.foto_profilo?.url" width="100" height="100" alt="">
          <span uk-icon="icon: trash; ratio: 3" class="overlay"></span>
        </div>

        <ng-template #showUploader>
          <label>
            Foto profilo
            <app-uploader [showDropBox]="false"
                          (imageData)="staffForm.controls['foto_profilo'].setValue($event)"></app-uploader>
          </label>
        </ng-template>
      </ng-container>


      <p class="uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close uk-margin-small-right" type="button">Cancel</button>
        <button class="uk-button uk-button-primary" type="submit">Save</button>
      </p>
    </form>
  </div>
</div>
