import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ListaAziendeComponent} from './core/lista-aziende/lista-aziende.component';
import {ChiSiamoComponent} from './core/chi-siamo/chi-siamo.component';
import {NewsComponent} from './core/news/news.component';
import {ContattiComponent} from './core/contatti/contatti.component';
import {HomeComponent} from './core/home/home.component';
import {LoginComponent} from './core/login/login.component';
import {NotFoundComponent} from './core/not-found/not-found.component';
import {AuthGuard} from './shared/auth.guard';
import {DashboardComponent} from './core/admin/dashboard/dashboard.component';
import {ArticlesComponent} from './core/admin/articles/articles.component';
import {AziendeComponent} from './core/admin/aziende/aziende.component';
import {StaffComponent} from './core/admin/staff/staff.component';


const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'aziende', component: ListaAziendeComponent},
  {path: 'chi-siamo', component: ChiSiamoComponent},
  {path: 'news-e-eventi', component: NewsComponent},
  {path: 'contatti', component: ContattiComponent},
  {
    path: 'admin', canActivate: [AuthGuard], component: DashboardComponent, children: [
      {path: 'articoli', component: ArticlesComponent},
      {path: 'aziende', component: AziendeComponent},
      {path: 'staff', component: StaffComponent}
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
