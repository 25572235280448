import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import {RouterModule} from '@angular/router';
import {DataService} from './services/data.service';
import { DropzoneDirective } from './dropzone.directive';
import { UploaderComponent } from './uploader/uploader.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from './auth.guard';


const COMPONENTS = [
  NavbarComponent,
  UploaderComponent,
  UploadTaskComponent
];

const PIPES = [];

@NgModule({
  declarations: [...COMPONENTS, DropzoneDirective],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ...COMPONENTS,
    DropzoneDirective
  ],
  providers: [DataService]
})
export class SharedModule { }
