import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DataService} from '../../../shared/services/data.service';
import {Observable} from 'rxjs';
import {Azienda} from '../../../models/azienda';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFireStorage} from '@angular/fire/storage';
import {Zona} from '../../../models/zona';
import * as uikit from 'uikit';
import {TeamMember} from '../../../models/team-member';
import {switchMap, take} from 'rxjs/operators';


@Component({
  selector: 'app-aziende',
  templateUrl: './aziende.component.html',
  styleUrls: ['./aziende.component.scss']
})
export class AziendeComponent implements OnInit {

  aziende$: Observable<Azienda[]>;
  zone$: Observable<Zona[]>;
  aziendeForm: FormGroup;
  newDocumentId: string;

  destroyUploader = false;

  constructor(private data: DataService, private storage: AngularFireStorage, private cd: ChangeDetectorRef) {
    this.aziendeForm = new FormGroup({
      id: new FormControl('', [Validators.required]),
      nome: new FormControl('', [Validators.required]),
      descrizione: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      logo: new FormControl(null, [Validators.required]),
      website_url: new FormControl('', [Validators.required]),
      zona: new FormControl('', [Validators.required]),
      ordine: new FormControl(0, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.aziende$ = this.data.aziende$;
    this.zone$ = this.data.zone$;

    uikit.util.on('#crea-nuovo', 'hide', () => {
      this.aziendeForm.reset();
    });

    uikit.util.on('#crea-nuovo', 'show', () => {
      this.aziendeForm.reset();
      this.newDocumentId = Date.now().toString();
    });
  }

  createNewAzienda(payload: TeamMember) {
    const modalEl = document.getElementById('crea-nuovo');

    return this.data.create(`aziende/${this.newDocumentId}`, {
      ...payload
    }).subscribe(() => {
      alert('Nuovo documento creato con successo!');
      this.aziendeForm.reset();
      uikit.modal(modalEl).hide();
      this.cd.detectChanges();
      this.destroyUploader = true;
      // this.uploadCanStart.unsubscribe();
    });
  }

  updateAzienda(payload: TeamMember) {
    console.log(payload);
    const modalEl = document.getElementById('aggiorna');
    return this.data.update(`aziende/${payload.id}`, {
      ...payload
    }).subscribe(() => {
      uikit.modal(modalEl).hide();
      alert('Documento aggiornato con successo!');
      this.aziendeForm.reset();
    });
  }

  deleteAzienda(azienda: Azienda) {
    if (confirm('Sicuro di voler procedere?')) {
      const logoPath = azienda.logo?.path;
      if (logoPath) {
        this.storage.ref(logoPath).delete().pipe(take(1)).subscribe();
      }
      return this.data.delete('aziende/' + azienda.id);
    }
  }

  deleteLogoImg(path: string, docId: string) {
    this.data.update(`aziende/${docId}`, {
      logo: null
    }).pipe(
      switchMap(() => this.storage.ref(path).delete()),
      take(1)
    ).subscribe(() => {
      this.aziendeForm.controls['logo'].setValue(null);
    });
  }


  openUpdateModal(documentData) {
    const modalEl = document.getElementById('aggiorna');
    this.aziendeForm.patchValue({...documentData});
    uikit.modal(modalEl).show();
  }

}


