<aside>
  <ul class="uk-list uk-list-striped">
    <li routerLink="articoli" routerLinkActive="active">Articoli</li>
    <li routerLink="aziende" routerLinkActive="active">Aziende</li>
    <li routerLink="staff" routerLinkActive="active">Staff</li>
  </ul>
</aside>
<main class="uk-padding-large uk-background-muted">
  <router-outlet></router-outlet>
</main>
